import React from 'react'
import ProductItem from '../../../products/ProductItem'

import SwiperCore, {
  Navigation, Pagination, Scrollbar, A11y, Thumbs, Lazy, Zoom, Autoplay
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([
  Navigation, Pagination, Scrollbar, A11y, Thumbs, Lazy, Zoom, Autoplay
])

export default function ProductListSlider({ appProps, settings, products, productsPerRowClass }) {
  const { productsPerRow, sliderSettings } = settings
  const { arrows, dots, scrollbar, loop, autoMove, moveDuration } = sliderSettings

  return (
    <Swiper
      effect='slide'
      navigation={ arrows }
      pagination={ dots && { clickable: true } }
      scrollbar={ scrollbar && { draggable: true } }
      loop={ loop }
      autoplay={ autoMove && moveDuration && { delay: moveDuration * 1000 } }
      slidesPerView={ productsPerRow }
      spaceBetween={ 10 }
      style={{ height: '100%', width: '100%' }}
      breakpoints={{
        0: { // when window width is >= 640px
          slidesPerView: 1
        },
        500: { // when window width is >= 500px
          slidesPerView: productsPerRow == 1 ? 1 : 2
        },
        640: { // when window width is >= 640px
          slidesPerView: productsPerRow == 1 ? 1 : 3
        },
        768: { // when window width is >= 768px
          slidesPerView: productsPerRow == 1 ? 1 : (productsPerRow == (6 || 5 || 4) ? 3 : productsPerRow)
        },
        900: { // when window width is >= 900px
          slidesPerView: productsPerRow == 1 ? 1 : (productsPerRow == (6 || 5) ? 4 : productsPerRow)
        },
        1080: { // when window width is >= 1080px
          slidesPerView: productsPerRow == 1 ? 1 : (productsPerRow == (6 || 5) ? 5 : productsPerRow)
        },
        1280: { // when window width is >= 1280px
          slidesPerView: productsPerRow == 1 ? 1 : productsPerRow
        }
      }}
    >
      { products.map( product => (
        <SwiperSlide
          key={ product.id }
          className={ productsPerRowClass }
        >
          <ProductItem
            appProps={ appProps }
            product={ product }
            quickViewActions={ appProps.productQuickActions }
            isOnSlider={ true }
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
