import React from "react"
import SwiperCore, { Navigation, Pagination, A11y, Thumbs, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ProductItem from "./ProductItem"

SwiperCore.use([ Navigation, Pagination, A11y, Thumbs, Lazy ])

export default function SimilarProducts({
  appProps, similarProducts
}) {
  return (
    <div className="main-container similar-products-wrapper">
      <div className="heading">
        <h2>
          { appProps.translations.products.similar_items }
        </h2>
      </div>

      <Swiper
        effect='slide'
        navigation={ true }
        pagination={ { clickable: true } }
        spaceBetween={ 10 }
        style={{ height: '100%', width: '100%' }}
        breakpoints={{
          0: { // when window width is >= 640px
            slidesPerView: 1
          },
          500: { // when window width is >= 500px
            slidesPerView: 2
          },
          640: { // when window width is >= 640px
            slidesPerView: 3
          },
          768: { // when window width is >= 768px
            slidesPerView: 4
          },
          900: { // when window width is >= 900px
            slidesPerView: 5
          },
          1280: { // when window width is >= 1280px
            slidesPerView: 6
          }
        }}
      >
        { similarProducts.map( (product, index) => {
            return (
              <SwiperSlide
                key={ product.id }
              >
                <ProductItem
                  appProps={ appProps }
                  key={ index }
                  product={ product }
                  showStockInfo={ false }
                  isOnSlider={ true }
                  quickViewActions={ false }
                />
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </div>
  )
}
